import React from "react"

import "./form.scss"

type Props = {
  type: string
  label: string
  onChange: Function
  value: string
}

const Input = (props: Props) => {
  const { type, label, onChange, value } = props

  return (
    <input
      defaultValue=""
      value={value}
      onChange={e => onChange(e)}
      className="input"
      type={type}
      placeholder={label}
    />
  )
}

export default Input
