import React from "react"

type Props = {
  html: any
  title: string
  tags: string
}

const ProjectCard = (props: Props) => {
  const { title, tags, html } = props

  function stringToTagsList(tagString: string): Array<string> {
    const tagSplit = tagString.split("|")
    return tagSplit
  }

  return (
    <div className="project-card">
      <h3 className="title">{title}</h3>
      <div className="tagsContainer">
        {stringToTagsList(tags).map(t => (
          <label key={t}>{t}</label>
        ))}
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  )
}

export default ProjectCard
