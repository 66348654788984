import React from "react"

import "./buttons.scss"

type Props = {
  text: string
  onClick: Function
}

const PrimaryButton = ({ text, onClick }: Props) => {
  return (
    <button className="primary-button" onClick={() => onClick()}>
      {text}
    </button>
  )
}

export default PrimaryButton
