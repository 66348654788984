import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, navigate } from "gatsby"

import "./projects.scss"
import Input from "../Form/Input"
import ProjectCard from "./ProjectCard"
import PrimaryButton from "../Buttons/PrimaryButton"

type Props = {
  full: boolean
}

const Projects = ({ full }: Props) => {
  const queryLong = graphql`
        query ProjectsQuery {
          allMarkdownRemark(
            sort: { fields: [frontmatter___released], order: DESC }
            filter: {fileAbsolutePath: {regex: "/projects-md\/.*\\.md$/" }}
          ) {
            totalCount
            edges {
              node {
                fileAbsolutePath
                frontmatter {
                  title
                  released
                  tags
                  categories
                }
                html
              }
            }
          }
        }
    `

  const projectsArray = useStaticQuery(queryLong)

  const [categorySelected, setCategorySelected] = useState("All")
  const [searchValue, setSearchValue] = useState("")
  const [projectsFiltered, setProjectsFiltered] = useState(
    projectsArray.allMarkdownRemark.edges
  )

  const getCategories = () =>
    projectsArray.allMarkdownRemark.edges.reduce(
      (acc, edge) => {
        const tempCat = edge.node.frontmatter.categories.split("|")
        for (let i = 0; i < tempCat.length; i++) {
          if (!acc.includes(tempCat[i])) {
            acc.push(tempCat[i])
          }
        }

        return acc
      },
      ["All"]
    )

  const [categories, setCategories] = useState(getCategories())

  function selectCategory(
    category: string,
    isFromSearch: boolean = false
  ): Array<any> {
    setCategorySelected(category)

    const edges = projectsArray.allMarkdownRemark.edges

    if (!isFromSearch && searchValue.length > 0) {
      search(searchValue, category)
      return
    }

    if (category === "All") {
      setProjectsFiltered(edges)
      return edges
    }
    let proFilArray = edges.filter(edge => {
      const categoriesTemp = edge.node.frontmatter.categories.split("|")
      return categoriesTemp.includes(category)
    })

    setProjectsFiltered(proFilArray)
    return proFilArray
  }

  function search(
    text: string,
    defaultCategory: string | null = null
  ): Array<any> {
    setSearchValue(text)
    const textLower = text.toLowerCase()
    const filterProjects = selectCategory(
      defaultCategory ? defaultCategory : categorySelected,
      true
    )

    const res = filterProjects.filter(p => {
      const tagsLower = p.node.frontmatter.tags.toLowerCase()
      const titleLower = p.node.frontmatter.title.toLowerCase()
      const html = p.node.html.toLowerCase()

      if (tagsLower.includes(textLower)) {
        return true
      }

      if (titleLower.includes(textLower)) {
        return true
      }

      if (html.includes(textLower)) {
        return true
      }

      return false
    })

    setProjectsFiltered(res)
    return res
  }

  function seeAllProjects() {
    navigate("/projects")
  }

  return (
    <div className="projects-container">
      <StaticImage
        className="left-bg"
        src="../../images/projects-bg-left.svg"
        alt="Background of projects section"
      />
      <StaticImage
        className="right-bg"
        src="../../images/projects-bg-right.svg"
        alt="Background of projects section"
      />
      <h2 className="title">
        <StaticImage
          class="mr"
          src="../../images/projects-icon.svg"
          alt="Projects icon"
        />
        Projects
      </h2>
      <div className="input-container">
        <Input
          value={searchValue}
          onChange={e => search(e.target.value)}
          type="text"
          label="Buscar"
        />
      </div>
      <div className="categories-container">
        {categories.map(c => {
          return (
            <a
              onClick={() => selectCategory(c, false)}
              className={categorySelected === c ? "selected" : ""}
            >
              {c}
            </a>
          )
        })}
      </div>
      <div className="projects-list-container">
        {projectsFiltered
          .slice(0, full ? projectsFiltered.length : 6)
          .map(edge => (
            <div key={edge.node.frontmatter.title} className="project-con">
              <ProjectCard
                html={edge.node.html}
                tags={edge.node.frontmatter.tags}
                title={edge.node.frontmatter.title}
              />
            </div>
          ))}
      </div>
      {!full && (
        <div className="show-more-container">
          <PrimaryButton text="See all" onClick={seeAllProjects} />
        </div>
      )}
    </div>
  )
}

export default Projects
